'use client'
import Image from 'next/image'
import { Message } from '@locmod/intl'
import { constants } from 'helpers'

import bgImage from 'public/images/error-bg.png'
import err500Image from 'public/images/error-500.png'

import { Button, buttonMessages } from 'components/inputs'

import messages from './messages'


type ErrorProps = {
  error: Error & { digest?: string }
  reset?: () => void
}

const Error: React.FC<ErrorProps> = (props) => {
  const { error, reset } = props

  return (
    <div className="relative -sm:min-h-[29rem] sm:min-h-[36rem] flex items-center justify-center">
      <Image
        className="absolute top-0 left-0 z-0 size-full object-center object-cover"
        src={bgImage}
        alt=""
        draggable={false}
      />
      <div className="px-6 text-center z-0 grid grid-cols-1 gap-6">
        <Image
          className="w-auto h-[6.25rem] mx-auto"
          src={err500Image}
          alt="Error 500"
        />
        <Message
          className="text-grey-80"
          value={messages.title}
          tag="h1"
        />
        <div className="mx-auto flex flex-col w-fit">
          {
            Boolean(reset) && (
              <Button
                title={buttonMessages.tryAgain}
                styling="primary"
                size={44}
                onClick={reset}
              />
            )
          }
          <Button
            className="mt-3"
            title={buttonMessages.goHome}
            to={constants.links.app}
            styling={reset ? 'tertiary' : 'primary'}
            rightIcon="interface/chevron-right"
            size={44}
          />
        </div>
      </div>
    </div>
  )
}

export default Error
